// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leichte-sprache-tsx": () => import("./../../../src/pages/leichte-sprache.tsx" /* webpackChunkName: "component---src-pages-leichte-sprache-tsx" */),
  "component---src-templates-blog-entry-tsx": () => import("./../../../src/templates/blog-entry.tsx" /* webpackChunkName: "component---src-templates-blog-entry-tsx" */),
  "component---src-templates-event-entry-tsx": () => import("./../../../src/templates/event-entry.tsx" /* webpackChunkName: "component---src-templates-event-entry-tsx" */)
}

